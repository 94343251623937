import { InsuranceVariantModel } from '@app/calculate/calculate/insurance-variant.model';
import {InsuranceType} from "@app/calculate/vehicle/vehicle.model";

export class CalculationResponseModel {
  variants: InsuranceVariantModel[];
  message: string;
  insuranceType: InsuranceType;
  ufgDown: boolean;
  offerNumber: string;
}
