import {CoverModel} from "@app/calculate/calculate/insurance-variant.model";
import {CalculationFormModel} from "@app/calculate/calculate/calculation-form-model";

export class PrepareSellRequestModel {
  covers: CoverModel[];
  formData: CalculationFormModel;
  packageName: string;
  sendingSellDocuments: boolean;
  insurerSendEmail: string;
  ownerSendEmail: string;
}
