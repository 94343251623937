import {CalculateConsent} from "@app/calculate/calculate/consent.model";


export class InsuranceStartDateModel {
  startDate: Date;
  promotionalCode: string;
  largeFamilyCardNumber: number;
  vitayCardNumber: number;
  selectedConsents: CalculateConsent[];
  allConsents: CalculateConsent[];
}
