import { CoverModel } from '@app/calculate/calculate/insurance-variant.model';

export class CalculationPlateDataModel {
  variant: string;
  price: string;
  covers: CoverModel[];
  additionalCovers: CoverModel[];
  startDate: Date;
  endDate: Date;
  installmentCount: string;
}
