import { GtmConsentModel } from "@app/shared/services/gtm/model/consent.model";
import {CalculateConsent} from "@app/calculate/calculate/consent.model";

export class ConsentMapper {

  public static map(selectedConsents: CalculateConsent[]): GtmConsentModel[] {
    return selectedConsents.map(consent => {
      let gtmConsent = new GtmConsentModel();
      gtmConsent.consentName = consent.key;
      gtmConsent.consentContent = consent.content;
      gtmConsent.consentAdditionalContent = consent.additionalContent;
      gtmConsent.consentObligatory = consent.required ? 1 : 0;
      return gtmConsent;
    });
  }

}
