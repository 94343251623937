import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {v4 as uuid} from 'uuid';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {CalculationStoreKey, CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {InputMode, LandingPageModel} from '@app/landing-page/landing-page.model';
import {PathModel} from "@app/landing-page/path.model";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {NgForm} from "@angular/forms";
import {CepikRequestModel} from "@app/landing-page/cepik-request.model";
import * as dayjs from "dayjs";
import {MAX_USER_AGE, OMNI_PARTNER_ID} from "@app/shared/constants/settings.constant";
import {CalculationService} from "@app/calculate/calculate/calculation.service";
import {CepikService} from "@app/shared/services/cepik/cepik.service";
import {UrlParamsStoreService} from "@app/shared/interceptors/partner/url-params-store.service";
import {VehicleRegistrationDataModel} from "@app/offer/vehicle-registration-data/vehicle-registration-data.model";
import {PersonalDataStoreModel, PersonalDataType} from "@app/shared/services/personal-data/personal-data-store.model";
import {PersonalDataStoreService} from "@app/shared/services/personal-data/personal-data-store.service";
import {Flags} from "@app/shared/models/flag/flag.model";
import {ShortPathInitRequest} from "@app/shared/services/cepik/short-path-init-request.model";
import {CepikPrepareCalculationRequest} from "@app/shared/models/cepik/cepik-prepare-calculation.model";
import {SUPPORTED_VEHICLE_TYPE} from "@app/landing-page/vehicle-type.model";
import {CallbackDialogComponent} from "@app/shared/components/callback/callback-dialog.component";
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {CepikResponse, GetProposalDataError} from "@app/shared/models/cepik/cepik-response.model";
import {DateUtilService} from "@app/shared/services/date-util/date-util.service";
import {catchError, filter, switchMap, tap} from "rxjs/operators";
import {distinctUntilChanged, Observable, of, Subject} from "rxjs";
import {Consent} from "@app/shared/services/consent/consent.model";
import {ConsentService} from "@app/shared/services/consent/consent.service";
import {ConsentCode} from "@app/shared/services/consent/consent-code.model";
import {OmniService} from "@app/shared/services/omni/omni.service";
import {OfferRecalculatedResponse} from "@app/shared/services/omni/omni.model";
import {ErrorCode, ErrorType} from "@app/shared/interceptors/server-error/error-info.model";
import {PrepareCalculationResponseModel} from "@app/calculate/calculate/prepare-calculation-response.model";
import {InsuranceStartDateModel} from "@app/calculate/insurance-start-date/insurance-start-date.model";
import {ToastService} from "@app/shared/services/toast/toast.service";
import {InfoDialogComponent} from "@app/shared/components/info-dialog/info-dialog.component";
import {CorrectDataDialogComponent} from "@app/landing-page/correct-data-dialog/correct-data-dialog.component";
import {AdParamsModel} from "@app/landing-page/ad-params.model";


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  path: typeof PathModel = PathModel;
  landingPage: LandingPageModel;
  cepikRequestModel: CepikRequestModel;
  personalData: PersonalDataStoreModel;
  vehicleRegistrationData: VehicleRegistrationDataModel;
  defaultBirthDate: Date;
  minBirthDate: Date;
  maxBirthDate: Date;
  consent: Consent;
  sid: string;
  oid: string;
  flagCorrectLicensePlate: boolean;
  flagCorrectBirthDate: boolean;
  omniTrafficSourceId: string;
  private _triggerNext$ = new Subject<any>();

  constructor(private router: Router,
              private dialog: MatDialog,
              private calculationStoreService: CalculationStoreService,
              private featureFlagService: FeatureFlagService,
              private calculationService: CalculationService,
              private cepikService: CepikService,
              private urlParamsStoreService: UrlParamsStoreService,
              private personalDataStore: PersonalDataStoreService,
              private dateUtilService: DateUtilService,
              private consentService: ConsentService,
              private toastService: ToastService,
              private omniService: OmniService) {
    const currentDate = new Date();

    this.cepikRequestModel = new CepikRequestModel();
    this.landingPage = new LandingPageModel();
    this.personalData = new PersonalDataStoreModel();
    this.vehicleRegistrationData = new VehicleRegistrationDataModel();
    this.defaultBirthDate = dayjs(currentDate).add(-30, 'years').toDate();
    this.minBirthDate = dayjs(currentDate).add(-MAX_USER_AGE, 'years').toDate();
    this.maxBirthDate = dayjs(currentDate).add(-16, 'years').toDate();
  }

  async ngOnInit() {
    this.sid = this.urlParamsStoreService.getInitShortPathSID();
    this.oid = this.urlParamsStoreService.getOmniId();
    this.omniTrafficSourceId = this.urlParamsStoreService.getOmniTrafficSourceId();
    this.consent = await this.consentService.getConsent(ConsentCode.INFO_OBLIGATION_WITH_PROFILING);
    this.flagCorrectLicensePlate = this.featureFlagService.isActive(Flags.FLAG_1808_CORRECT_LICENSE_PLATE);
    this.flagCorrectBirthDate = this.featureFlagService.isActive(Flags.FLAG_1809_CORRECT_BIRTH_DATE);

    if (this.sid) {
      this.setLandingPageModel(PathModel.CEPIK);

      this.cepikService.getSession(this.sid).pipe(
        tap(response => {
          this.storeVehicleRegistrationData(response.licensePlateNumber);
          this.storeBirthDate(this.dateUtilService.stringToUtc(response.birthDate));
        }),
        switchMap(_ => this.omniPrepareCalculation()),
        switchMap(_ => this.initOmniPath()),
        tap(_ => this.setLandingPageModel(PathModel.OMNI)),
        filter(omniResult => !!omniResult.errors?.length),
        tap(_ => this.clearOmniPath()),
        tap(_ => this.setLandingPageModel(PathModel.CEPIK)),
        switchMap(_ => this.initCepikPathRequest(window.location.href)),
        tap(proposalData => this.autoChoosePath(proposalData))
      ).subscribe();
    }

    if (this.oid) {
      this.storeVehicleRegistrationData(this.cepikRequestModel.licensePlateNumber).pipe(
        tap(_ => this.urlParamsStoreService.storePartnerId(OMNI_PARTNER_ID)),
        switchMap(_ => this.omniPrepareCalculation()),
        switchMap(_ => this.omniService.getOfferByHash(
          this.oid,
          this.calculationStoreService.getCalculationId(),
          this.omniTrafficSourceId
        )),
        tap(response => this.storeOmniResponse(response)),
        tap(_ => this.setLandingPageModel(PathModel.OMNI)),
        tap(_ => this.goToOmniPath()),
        catchError((err, caught) => {
          this.toastService.info('Link do oferty wygasł. Wprowadź dane aby wyliczyć nową ofertę.');
          this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_ID);
          this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_DICTIONARY);
          this.urlParamsStoreService.clear();
          return of(err);
        })
      ).subscribe();
    }
  }

  storeLandingPage(path) {
    return this.calculationStoreService.storeLandingPage(this.landingPage).subscribe(() => {
      switch (path) {
        case PathModel.DECLARATION:
          this.calculationStoreService.clearStep(Step.VEHICLE_OWNER);
          this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_ID);
          this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_DICTIONARY);
          this.calculationService.prepareCalculation();
          return this.router.navigate([Step.VEHICLE]);
      }
    });
  }

  private setInputMode(path) {
    switch (path) {
      case PathModel.DECLARATION:
        return this.landingPage.inputMode = InputMode.UFG;
      case PathModel.CEPIK:
        return this.landingPage.inputMode = InputMode.CEPIK;
      case PathModel.OMNI:
        return this.landingPage.inputMode = InputMode.OMNI;
    }
  }

  onInputModeTypeChange(path: PathModel) {
    this.setInputMode(path);
    this.setFlowId();
    this.storeLandingPage(path);
  }

  private setFlowId() {
    this.calculationStoreService.storeFlowId(uuid())
  }

  private setLandingPageModel(pathModel: PathModel) {
    this.setInputMode(pathModel);
    this.consent.selected = true;
    this.landingPage.consent = this.consent;
    this.landingPage.licensePlate = this.cepikRequestModel.licensePlateNumber;

    return this.storeLandingPage(pathModel);
  }

  async initCepikPathRequest(initShortPathUrl) {
    const params = this.getQueryParams(initShortPathUrl);
    const calculationId = this.calculationStoreService.getCalculationId();
    const prepareCalc = await this.prepareCalculation(calculationId, params.sid);

    return await this.getProposalData(calculationId);
  }

  next(form: NgForm) {
    if (form.invalid) { return; }

    if (!this._triggerNext$.observers.length) {
      this._triggerNext$.pipe(
          distinctUntilChanged(),
          switchMap(_ => this.storeVehicleRegistrationData(this.cepikRequestModel.licensePlateNumber)),
          switchMap(_ => this.storeBirthDate(this.cepikRequestModel.birthDate)),
          switchMap(_ => this.omniPrepareCalculation()),
          switchMap(_ => this.initOmniPath()),
          tap(_ => this.setLandingPageModel(PathModel.OMNI)),
          filter(omniResult => !!omniResult.errors?.length),
          tap(_ => this.clearOmniPath()),
          tap(_ => this.setLandingPageModel(PathModel.CEPIK)),
          switchMap(_ => this.initShortPath()),
          switchMap(initShortPathUrl => this.initCepikPathRequest(initShortPathUrl)),
          tap(proposalData => this.autoChoosePath(proposalData))
      ).subscribe();
    }

    this._triggerNext$.next(this.calculationStoreService.getCalculationId());
  }

  private storeVehicleRegistrationData(licensePlate: string) {
    this.vehicleRegistrationData.licensePlateNumber = licensePlate;
    return this.calculationStoreService.storeVehicleRegistrationData(this.vehicleRegistrationData);
  }

  private storeBirthDate(birthDate: Date) {
    const storeData = this.personalDataStore.getPersonalData(PersonalDataType.OWNER);

    return this.personalDataStore.storePersonalData(PersonalDataType.OWNER, {
      ...this.personalData,
      ...storeData,
      birthDate: birthDate
    });
  }

  private correctDataDialog() {
    this.calculationStoreService.storeLandingPageCorrectDataCount(1);

    const dialogRef = this.dialog.open(CorrectDataDialogComponent, {
      panelClass: 'callback-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.goToDeclarationPath) {
        this.goToDeclarationPath();
      }
    });
  }

  private autoChoosePath(proposalData: CepikResponse) {
    const errors = proposalData?.errors;
    const errorLicensePlateNotExist = errors?.filter(err => err.id === GetProposalDataError.LICENSE_PLATE_NOT_EXIST);
    const errorPeselNotMatch = errors?.filter(err => err.id === GetProposalDataError.PESEL_NOT_MATCH);
    const errorCepikTechnical = errors?.filter(err => err.id === GetProposalDataError.CEPIK_TECHNICAL_ERROR);
    const storeCorrectDataCount = this.calculationStoreService.getLandingPageCorrectDataCount();

    if (this.flagCorrectLicensePlate &&
        !storeCorrectDataCount && errorLicensePlateNotExist?.length && !errorPeselNotMatch?.length && !errorCepikTechnical?.length) {
      return this.correctDataDialog();
    }

    if (this.flagCorrectBirthDate &&
        !storeCorrectDataCount && errorPeselNotMatch?.length && !errorCepikTechnical?.length) {
      return this.correctDataDialog();
    }

    if (!proposalData?.vehicle?.matchingModels?.length ||
        !proposalData?.policyContacts?.length ||
        !proposalData?.policyContacts[0]?.zipCode) {
      return this.goToDeclarationPath();
    } else if (SUPPORTED_VEHICLE_TYPE.includes(proposalData.vehicle.matchingModels[0].vehicleTypeId)) {
      return this.goToCepikPath();
    } else {
      return this.openCallbackVehicleNotSupported();
    }
  }

  private initOmniPath() {
    const error = {
      id: 'OMNI_PATH',
      code: ErrorCode.OMNI_PATH,
      type: ErrorType.SYSTEM,
      message: 'OMNI_PATH'
    };

    return this.omniService.getOffer().pipe(
        tap(response => this.storeOmniResponse(response)),
        tap((request) => {
          !request.errors?.length ? this.goToOmniPath() : null;
        }),
        catchError((err, caught) => {
          return of(new OfferRecalculatedResponse(error));
        })
    );
  }

  private storeOmniResponse(response: OfferRecalculatedResponse) {
    const insuranceStartDate = new InsuranceStartDateModel();
          insuranceStartDate.startDate = this.dateUtilService.stringToUtc(response.omniDetails.startDate);

    this.calculationStoreService.storeOmniOfferData(response);
    this.calculationStoreService.storeOfferNumber(response.omniDetails.offerNumber);
    this.calculationStoreService.storeOfferRenewal(response.omniDetails.renewal);
    this.calculationStoreService.storeInsuranceStartDate(insuranceStartDate);
    this.calculationStoreService.storeSelectedCovers(response.variant.covers);
    this.urlParamsStoreService.store({ "sid": response.sid  });
  }

  async initShortPath() {
    const request: ShortPathInitRequest = {
      birthDate: dayjs(this.personalDataStore.getPersonalData(PersonalDataType.OWNER).birthDate),
      licensePlateNumber: this.calculationStoreService.getVehicleRegistrationData().licensePlateNumber,
      partnerId: this.urlParamsStoreService.getPartnerId()
    };

    return await this.cepikService.shortPathInit(request);
  }

  async prepareCalculation(calculationIdentifier: string, cepikSessionId: string) {
    const request: CepikPrepareCalculationRequest = {
      calculationIdentifier,
      cepikSessionId
    };

    return await this.cepikService.prepareCalculation(request);
  }

  async getProposalData(calculationIdentifier) {
    return await this.cepikService.getProposalData({
      calculationIdentifier
    });
  }

  private omniPrepareCalculation(): Observable<PrepareCalculationResponseModel> {
    return this.calculationService.prepareCalculation();
  }

  navigateToDeclarationPath() {
    this.onInputModeTypeChange(PathModel.DECLARATION);
  }

  private goToOmniPath() {
    const renewal = this.calculationStoreService.getOmniOfferData().omniDetails.renewal;
    let queryParans = {};

    if (renewal) {
      queryParans[AdParamsModel.OFFER_RENEWAL] = true;
    }

    return this.router.navigate([OfferStep.OMNI_OFFER], {
      queryParams: queryParans
    });
  }

  private goToCepikPath() {
    return this.router.navigate([Step.SHORT_PATH]);
  }

  private goToDeclarationPath() {
    const landingPage = this.calculationStoreService.getLandingPage();
          landingPage.inputMode = InputMode.UFG;

    this.calculationStoreService.storeLandingPage(landingPage);
    this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_ID);
    this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_DICTIONARY);
    this.calculationStoreService.clearStep(Step.VEHICLE_OWNER);

    return this.router.navigate([Step.VEHICLE]);
  }

  private clearOmniPath() {
    this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_ID);
    this.calculationStoreService.clearCalculationStoreKey(CalculationStoreKey.CALCULATION_DICTIONARY);
  }

  private getQueryParams(url) {
    const urlObj = new URL(url);
    const urlSearchParams = new URLSearchParams(urlObj.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return {
      partner_id: params.partner_id,
      sid: params.sid
    }
  }

  private openCallbackVehicleNotSupported() {
    return this.dialog.open(CallbackDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'callback-dialog',
      data: {
        closeDialogCallback: () => {
          this.calculationStoreService.clear();
          this.personalDataStore.clear();
          this.dialog.closeAll()
        },
        errorCode: 'VEHICLE_TYPE_NOT_SUPPORTED'
      },
    });
  }

}
