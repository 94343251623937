import {ErrorInfo} from "@app/shared/interceptors/server-error/error-info.model";
import {CoverModel, InsuranceVariantModel} from "@app/calculate/calculate/insurance-variant.model";
import {CalculationFormModel} from "@app/calculate/calculate/calculation-form-model";

export class OfferRecalculatedResponse {
  constructor(error) {
    this.errors = [error];
  }

  errors?: ErrorInfo[];
  variant: InsuranceVariantModel;
  vehicle: OmniVehicleModel;
  omniDetails: OmniDetails;
  omniVehicleOwnerDetails: OmniVehicleOwnerDetailsModel;
  sid: string;
  warnings: OmniWarning[];
}

export interface OmniVehicleModel {
  modelId: number;
  modelYear: number;
  vin: string;
  licensePlate: string;
}

export interface OmniDetails {
  calculationId: string;
  offerNumber: string;
  renewal: boolean;
  startDate: string;
  contactsCount: number;
  usageId: string;
  contacts: Roles[];
}

export interface OmniCalculationRequest {
  calculationId: string;
  formData: CalculationFormModel;
  covers: CoverModel[];
}

export interface OmniVehicleOwnerDetailsModel {
  driverLicenseObtainYear: string;
  youngOwner: boolean;
  youngestOwnerAge: string;
  livingZipCode: string;
  kdrNumber: number;
}

export interface RetrievedOmniOffer {
  policyHeaderId: number;
  policyNumber: string;
  licensePlate: string;
  expiresAt: string;
  contactIds: number[];
}

export interface Roles {
  roles: Role[];
}

export enum Role {
  OWNER = 'OWNER',
  COOWNER = 'COOWNER',
  MAIN_DRIVER = 'MAIN_DRIVER',
  INSURER = 'INSURER',
}

export interface OmniWarning {
  code: string;
  message: string;
}